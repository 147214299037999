.project {
    &-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;

        p {
            margin: 0;
            overflow-wrap: anywhere;
        }
    }

    &-block {
        --project-background: linear-gradient(
            135deg,
            var(--first-color),
            var(--second-color)
        );
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        background: var(
            --project-background,
            url("../../../assets/project_background_1.png")
        );
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        align-items: center;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 16px;
        flex-shrink: 0;
        flex: 1;
        overflow: hidden;
        cursor: pointer;
        transition: 0.3s;
    }

    &-buttons {
        position: absolute;
        display: flex;
        gap: 10px;
        flex-direction: column;
        bottom: 30px;
        right: 10px;
    }

    &-link {
        &-button-icon {
            width: 31px;
            fill: var(--white);
            transition: 0.3s;
        }

        &:active {
            background-color: var(--white);
            border: 1px solid var(--additional-color-3);

            & .project-link-button-icon {
                fill: var(--black);
            }
        }
    }

    &-checkout {
        &-button-icon {
            width: 20px;
            fill: var(--white);
            transition: 0.3s;
        }

        &:active {
            background-color: var(--white);
            border: 1px solid var(--additional-color-3);

            & .project-checkout-button-icon {
                fill: var(--black);
            }
        }
    }

    &-image-preview {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 95%;
        top: 10%;
        bottom: auto;
        left: 0;
        right: 0;
        user-select: none;
        transform: rotate(-15deg);
        transition: 0.3s;

        &-base {
            width: 100%;
            height: 100%;
        }

        &-icon {
            position: absolute;
            width: 80.45%;
            height: 80.45%;
            top: 18.5%;
            left: 18.5%;
            background-position: 0 0;
            background-size: 100%;
            background-repeat: no-repeat;
        }
    }

    &-tags-block {
        display: flex;
        gap: 20px;
        width: 100%;
        flex-wrap: wrap;
    }

    &-tag {
        display: flex;
        align-items: center;
        color: var(--white);
        background-color: var(--additional-color-1);
        border-top: 1px solid var(--additional-color-3);
        border-left: 1px solid var(--additional-color-3);
        border-radius: 8rem;
        padding: 0.15rem 0.55rem 0.15rem 0.5rem;
        font-size: 1rem;
        font-weight: 700;
        gap: 10px;
        cursor: pointer;
        transition: all 0.2s;

        svg {
            width: 20px;
            height: 20px;
            user-select: none;
        }

        p {
            white-space: nowrap; /* Prevent text wrapping */
            overflow: visible; /* Ensure content is fully displayed */
            text-overflow: unset; /* Remove ellipsis truncation */
            max-width: none; /* Allow full width of content */
            flex-shrink: 0; /* Prevent shrinking in the flex container */
        }

        &:active {
            background-color: var(--additional-color-4);
        }
    }
}

.inner-container {
    position: relative;
    padding-top: 65%;
    width: 100%;
    transition: 0.3s;

    &:active {
        & .project-image-preview {
            transform: rotate(-5deg);
        }
    }
}

.not_scrolled {
    filter: brightness(0.5) saturate(0%);
    -webkit-filter: brightness(0.5) saturate(0%);
}

.project__btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    box-shadow: 0 4px 4px #4f4f4f;
    background-color: rgba(77, 77, 77, 0.33);
    border: 1px solid var(--additional-color-3);
    border-radius: 100%;
    cursor: pointer;
    transition: 0.3s;
}

@media (min-width: 992px) {
    .project-container {
        width: 40%;
    }
}

@media (min-width: 481px) and (max-width: 991px) {
    .project-container {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .project-container {
        width: 100%;
    }

    .project__btn {
        width: 30px;
        height: 30px;
    }

    .project-checkout-button-icon {
        width: 15px;
    }

    .project-link-button-icon {
        width: 21px;
    }
}

@media (hover: hover) {
    .project-tag:hover {
        background-color: var(--additional-color-4);
    }

    .project-checkout:hover {
        background-color: var(--white);
        border: 1px solid var(--additional-color-3);

        & .project-checkout-button-icon {
            fill: var(--black);
        }
    }

    .project-link:hover {
        background-color: var(--white);
        border: 1px solid var(--additional-color-3);

        & .project-link-button-icon {
            fill: var(--black);
        }
    }

    .inner-container:hover {
        & .project-image-preview {
            transform: rotate(-5deg);
        }
    }
}
