.we-can-offer {
    &-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 125px;
        box-sizing: border-box;
    }

    &-header {
        display: flex;
        width: 70%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
    }

    &-content {
        width: 70%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border-radius: 20px;
        border: 2px solid var(--additional-color-4);
        overflow: hidden;
    }

    &-title {
        display: flex;
        flex-direction: column;
        gap: 20px;

        & p {
            margin: 0;
            max-width: 50%;
        }
    }
}

@media (max-width: 1199px) {
    .we-can-offer {
        &-header {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
        }

        &-title {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;

            & p {
                max-width: 100%;
            }
        }
    }
}

@media (max-width: 991px) {
    .we-can-offer {
        &-block {
            width: 90%;
        }

        &-header {
            width: 85%;
        }

        &-content {
            width: 90%;
        }
    }
}

@media (max-width: 480px) {
    .we-can-offer-block {
        padding-top: 85px;
    }
}
