.our-stack {
    &-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }

    &-content {
        display: flex;
        gap: 100px;
        flex-wrap: wrap;
        justify-content: center;
        width: 80%;
    }

    &-title p {
        margin: 0;
        text-align: center;
    }
}

@media (max-width: 991px) {
    .our-stack-content {
        width: 100%;
    }
}
