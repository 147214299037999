.window-modal {
    position: relative;
    max-width: 500px;
    width: 500px;
    min-height: 100px;
    max-height: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: var(--background-color);
    border-radius: 16px;
    padding: 20px;

    &-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--additional-color-3);
        z-index: 100;
    }

    &-inner {
        max-width: 100%;
        max-height: 100%;
        padding: 10px;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
    }

    &-info {
        width: 100%;
        text-align: center;
        overflow-wrap: anywhere;
    }

    &-background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: linear-gradient(
            98deg,
            var(--additional-primary-color) 0%,
            var(--secondary-color) 100%
        );
        border-radius: 16px;
        transform: translate(2px, 2px);
        z-index: -1;
        transition: 0.3s;
    }

    &-button-container {
        width: 50%;
        height: 40px;
    }
}

@media (max-width: 520px) {
    .window-modal {
        max-width: 90%;
    }
}
