.projects {
    &-container {
        position: relative;
        justify-content: center;
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        gap: 100px;

        &-header {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding-top: 50px;
            box-sizing: border-box;
            gap: 20px;
        }
    }

    &-text {
        display: flex;
        width: 80%;
        justify-content: space-between;
        align-items: center;

        & p {
            max-width: 40%;
        }
    }

    &-empty {
        text-align: center;
        overflow-wrap: break-word;
    }
}

.project-filters-block {
    position: relative;
    width: 75%;
    display: flex;
    justify-content: flex-end;
}

.drop-down {
    position: relative;
    display: flex;
    align-items: center;
    grid-column-gap: 0.75rem;
    font-size: 25px;
    color: var(--white);
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    padding: 0.7rem 1.25rem 0.75rem;
    opacity: 0.5;
    user-select: none;
    cursor: pointer;
    transition: 0.3s;

    & svg {
        width: 25px;
    }

    &:active {
        opacity: 1;
        border-bottom: 2px solid var(--white);
    }

    &.active {
        opacity: 1;
        border-bottom: 2px solid var(--white);
    }

    &-list {
        position: absolute;
        top: 110%;
        right: 0;
        width: 200px;
        max-height: 200px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: 20px;
        opacity: 0;
        background-color: var(--white);
        border-radius: 6px;
        padding: 5px 0;
        box-shadow: 0 0 5px var(--black);
        overflow-y: auto;
        overflow-y: hidden;
        transform: scale(0%);
        transition: 0.3s;
        z-index: 2;

        &.active {
            transform: scale(100%);
            opacity: 1;
        }
    }

    &-item {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        color: var(--black);
        padding: 0 35px;
        box-sizing: border-box;
        overflow-wrap: anywhere;
        user-select: none;
        cursor: pointer;
        transition: 0.3s;

        &:active {
            background-color: var(--black) 1e;
        }
    }
}

.filter-checkbox {
    position: absolute;
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    margin: 2px;
    background-color: transparent;
    border-radius: 100%;
    border: 1px solid var(--black);
    cursor: pointer;
    left: 5px;
    transition: 0.3s;

    &:checked {
        background-color: var(--primary-color);
    }
}

.side-text {
    will-change: transform;
    position: absolute;
    left: -470px;
    top: 10%;
    white-space: nowrap;
    font-size: 102px;
    color: var(--additional-color-1);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--additional-color-3);
    text-transform: uppercase;
    user-select: none;
}

@media (max-width: 991px) {
    .drop-down-list {
        right: 10px;
    }

    .projects {
        &-container {
            width: 80%;
        }

        &-text {
            width: 80%;
            flex-direction: column;
            margin-bottom: 20px;

            & p {
                max-width: 100%;
            }
        }
    }
}

@media (max-width: 650px) {
    .projects-text {
        width: 70%;
        flex-direction: column;
        margin-bottom: 20px;

        & p {
            max-width: 100%;
        }
    }
}

@media (max-width: 650px) {
    .project-filters-block {
        width: 75%;
    }
}

@media (hover: hover) {
    .drop-down {
        &:hover {
            opacity: 1;
            border-bottom: 2px solid var(--white);
        }

        &-item:hover {
            background-color: var(--black) 1e;
        }
    }
}
