.questions {
    &-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 125px;
        padding-bottom: 125px;
        box-sizing: border-box;
    }

    &-header {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;

        & p {
            margin: 0;
        }
    }

    &-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}

@media (max-width: 991px) {
    .questions-container {
        width: 90%;
    }
}

@media (max-width: 480px) {
    .questions-container {
        padding-top: 80px;
    }
}
