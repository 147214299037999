.collage {
    width: max-content;
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 200px));
    gap: 16px;
    background-color: var(--additional-color-2);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 15px;

    .thumbnail-container {
        width: 100%;
        aspect-ratio: 1/1;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 6px;
        cursor: pointer;
        border: 1px solid var(--background-additional-color);
        transition: 0.3s;

        &:active {
            box-shadow: 0 0 15px var(--background-color);
            scale: 90%;
        }
    }
}

@media (hover: hover) {
    .collage .thumbnail-container:hover {
        box-shadow: 0 0 15px var(--background-color);
    }
}
