.page-not-found {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
}

.page-not-found-content {
    width: 400px;
    height: auto;
    font-size: 102px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.page-not-found-text {
    width: 400px;
    height: auto;
    font-size: 102px;
    display: flex;
    align-items: center;
    flex-direction: column;

    & p {
        margin: 0;
    }
}
