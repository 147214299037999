.button {
    position: relative;
    height: 63px;
    width: 250px;
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    flex-shrink: 0;
    user-select: none;
    cursor: pointer;
    background: none;
    border: none;

    &:active {
        & .button-foreground {
            transform: translate(-2px, -2px);
        }

        & .button-background {
            transform: translate(12px, 12px);
        }
    }

    &-foreground {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--additional-color-2);
        color: var(--white);
        border-radius: 16px;
        backdrop-filter: blur(3px);
        font-size: 25px;
        font-weight: 700;
        z-index: 1;
        border-bottom: 2px solid var(--additional-color-2);
        border-right: 2px solid var(--additional-color-2);
        transition: 0.3s;
    }

    &-background {
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            98deg,
            var(--additional-primary-color) 0%,
            var(--secondary-color) 100%
        );
        border-radius: 16px;
        transform: translate(10px, 10px);
        transition: 0.3s;
    }
}

@media (max-width: 480px) {
    .button {
        width: 100%;
        height: 35px;

        &-foreground {
            font-size: 20px;
        }
    }
}

@media (max-width: 300px) {
    .button-foreground {
        font-size: 15px;
    }
}

@media (hover: hover) {
    .button:hover {
        & .button-foreground {
            transform: translate(-2px, -2px);
        }

        & .button-background {
            transform: translate(12px, 12px);
        }
    }
}
