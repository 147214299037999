.what-is {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 20px;

    &-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 50px;
    }

    & p {
        margin: 0;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .what-is {
        width: 80%;
    }
}

@media (max-width: 991px) {
    .what-is {
        width: 90%;

        &-buttons {
            flex-direction: column;
            gap: 20px;
        }
    }
}

@media (max-width: 480px) {
    .what-is-buttons {
        width: 100%;
    }
}
