.technology {
    &-tech {
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        gap: 20px;

        &-small {
            display: flex;
            align-items: center;
            height: 73px;
            justify-content: flex-end;
            flex-direction: column;
            gap: 5px;
            filter: none;
            -webkit-filter: none;
            opacity: 1;
            font-weight: 500;
            transition: 0.3s;

            & img {
                max-width: 50px;
                max-height: 50px;
                user-select: none;
            }
        }
    }

    &-name {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 35px;
        font-weight: 600;
        text-transform: uppercase;

        & p {
            margin: 0;
        }
    }
}

.technologies {
    width: 90%;
    display: flex;
    justify-content: center;
    gap: 20px;
    font-size: 20px;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;

    & p {
        margin: 0;
    }
}

@media (max-width: 991px) {
    .technology-tech {
        width: 100%;
    }
}

@media (hover: hover) {
    .technology-tech-small {
        display: flex;
        align-items: center;
        height: 73px;
        justify-content: flex-end;
        flex-direction: column;
        gap: 5px;
        filter: brightness(0.5) saturate(0%);
        -webkit-filter: brightness(0.5) saturate(0%);
        opacity: 0.55;
        font-weight: 500;
        transition: 0.3s;

        &:hover {
            filter: none;
            -webkit-filter: none;
            opacity: 1;
        }
    }
}
