.main-page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 150px;
    gap: 50px;
}

@media (max-width: 1199px) {
    .side-text {
        display: none;
        opacity: 0;
        transform: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .drop-down-list {
        right: auto;
    }
}
