.offer-block {
    position: relative;
    width: 30%;
    flex: auto;
    flex-shrink: 0;
    outline: 1px solid var(--additional-color-4);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    overflow: hidden;
}

.offer-icon {
    width: 7rem;
    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px solid var(--additional-color-3);
    border-left: 2px solid var(--additional-color-3);
    border-radius: 100%;
    margin-bottom: 1.5rem;
    padding: 1rem;
    background-image: linear-gradient(
        315deg,
        rgba(255, 255, 255, 0),
        var(--additional-color-2)
    );
    overflow: hidden;

    & img {
        width: 80%;
        height: 80%;
    }
}

.offer-block-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & .mid-text {
        margin: 0;
        max-width: 100%;
        text-align: center;
        overflow-wrap: anywhere;
    }

    & .small-text {
        margin: 0;
        max-width: 80%;
        text-align: center;
        overflow-wrap: anywhere;
    }
}

@media (max-width: 1199px) {
    .offer-block {
        width: 300px;
    }
}

@media (max-width: 991px) {
    .offer-icon {
        width: 50px;
        height: 50px;
    }
}
