.footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 50px 50px 200px 50px;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(
            90deg,
            transparent 0%,
            var(--white) 50%,
            transparent 100%
        )
        1 0 0 0;
    background-color: var(--background-color);
    gap: 100px;
    bottom: 0;
    box-sizing: border-box;

    & .logo-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        & .logo {
            display: flex;
            gap: 10px;
            align-items: center;

            & img {
                max-width: 82px;
                max-height: 82px;
            }
        }
    }
}

.categories {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.category {
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 15px;

    .mid-text {
        text-transform: unset;
        font-weight: 600;
    }

    li > * {
        width: fit-content;
        text-decoration: none;
        cursor: pointer;

        &:active {
            text-decoration: underline;
        }
    }

    & p {
        margin: 0;
    }
}

@media (max-width: 991px) {
    .footer {
        align-items: center;
        justify-content: center;
        flex-direction: column;

        & .logo-container .logo {
            width: 100%;
            justify-content: center;
        }
    }

    .categories {
        justify-content: center;
        gap: 50px;
    }

    .category {
        padding: 0;
    }
}

@media (min-width: 481px) and (max-width: 650px) {
    .footer {
        padding: 2px;
    }
}

@media (max-width: 480px) {
    .footer {
        padding: 2px;

        & .logo-container {
            text-align: center;

            & .logo img {
                max-width: 48px;
                max-height: 48px;
            }
        }
    }
}

@media (hover: hover) {
    .category li > *:hover {
        text-decoration: underline;
    }
}
