.preview-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: 65px;
    background-color: var(--background-additional-color);
    box-sizing: border-box;
    overflow-y: auto;
    z-index: 10;

    .arrow {
        position: absolute;
        top: 50%;
        width: 35px;
        height: 35px;
        transform: translateY(50%);
        cursor: pointer;

        &.right {
            right: 10px;

            svg {
                transform: rotate(180deg);
            }
        }

        &.left {
            left: 10px;
        }

        svg {
            width: 100%;
            height: 100%;
            color: var(--white);

            path {
                transition: 0.3s;
            }
        }

        &:active svg path {
            color: var(--primary-color);
        }
    }

    .source {
        width: 100%;

        &-container {
            max-width: 1200px;
            width: 100%;
            height: auto;
            place-self: center;
            user-select: none;
        }

        &-count {
            position: fixed;
            bottom: 50px;
            left: 20px;
            user-select: none;
        }
    }
}

@media (max-width: 680px) {
    .preview-container {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (hover: hover) {
    .preview-container .arrow:hover svg path {
        color: var(--primary-color);
    }
}
