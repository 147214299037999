.detailed-project-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 150px;
    gap: 270px;
    overflow-x: hidden;
    overflow-y: auto;

    &-inner {
        width: 90%;
        display: flex;
        flex-direction: column;
        gap: 50px;

        &-content {
            display: flex;
            gap: 50px;

            .sources {
                width: 100%;
                max-width: 700px;
                display: flex;
                flex-direction: column;
                gap: 25px;
            }

            .description {
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 5px;
            }
        }
    }

    &-header {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 25px;

        &-right {
            width: 30%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 10px;

            &-button {
                max-width: 300px;

                .drop-down {
                    padding-left: 2px;
                }
            }

            &-tags {
                display: flex;
                gap: 20px;
                flex-wrap: wrap;
            }
        }
    }
}

@media (max-width: 1100px) {
    .detailed-project-page {
        &-inner-content {
            display: flex;
            flex-direction: column-reverse;
            gap: 50px;

            .description {
                width: 100%;
            }
        }

        &-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            gap: 50px;

            &-right {
                width: 100%;
            }
        }
    }
}
