.navbar {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 150px;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(
            90deg,
            var(--transparent-color) 0%,
            var(--white) 50%,
            var(--transparent-color) 100%
        )
        0 0 1 0;
    background-color: var(--background-color) 7d;
    gap: 100px;
    box-sizing: border-box;
    z-index: 19;
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    overflow-x: hidden;
    transition: 0.3s;

    & .logo {
        display: flex;
        gap: 10px;
        align-items: center;

        & img {
            max-width: 82px;
            max-height: 82px;
        }
    }
}

.navbar-first-part {
    display: flex;
    gap: 100px;
    align-items: center;
}

.navbar-buttons {
    display: flex;
    gap: 20px;
    align-items: center;
    text-align: center;
}

.navbar-buttons-last {
    display: flex;
    gap: 20px;
    align-items: center;
    text-align: center;
}

.navbar-buttons-additional {
    display: flex;
    gap: 20px;
    align-items: center;
    text-align: center;
}

.change-theme-button {
    width: 40px;
    height: 40px;
    border: none;
    background: transparent;
    cursor: pointer;

    & svg {
        width: 100%;
        height: 100%;
        color: var(--white);
        transition: 0.3s;
    }
}

.navbar-mobile {
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(
            90deg,
            var(--transparent-color) 0%,
            var(--white) 50%,
            var(--transparent-color) 100%
        )
        0 0 1 0;
    background-color: var(--background-color) 7d;
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    z-index: 20;

    & .logo {
        display: flex;
        align-items: center;
        justify-content: center;

        & a {
            font-size: 20px !important;
        }

        & img {
            max-width: 35px;
            max-height: 80%;
        }
    }
}

.navbar-mobile-button {
    display: flex;
    justify-content: center;
    align-items: center;
    fill: var(--white);
    width: 35px;
    height: 35px;
    box-shadow: 0 4px 4px #4f4f4f;
    background-color: rgba(77, 77, 77, 0.33);
    border: 1px solid var(--additional-color-3);
    border-radius: 100%;
    cursor: pointer;
    z-index: 21;
    transition: 0.3s;

    & svg {
        width: 70%;
        height: 70%;
    }

    &.active {
        fill: var(--black);
        background-color: var(--white);
        border: 1px solid var(--additional-color-3);
    }
}

@media (min-width: 1199px) {
    .navbar-mobile-button {
        display: none;
        opacity: 0;
    }
}

@media (max-width: 1198px) {
    .navbar-mobile-button {
        display: flex;
        opacity: 1;
    }

    #navbar {
        display: none;
        padding: 0;
        gap: 20px;
        flex-direction: column;

        &.active {
            display: flex;
            height: 100vh;
            top: 0 !important;
            bottom: 0;
            justify-content: center;

            & .logo {
                max-width: 100%;
                scale: 90%;

                & img {
                    max-width: 48px;
                    max-height: 48px;
                }
            }
        }
    }

    .navbar-first-part,
    .navbar-buttons-last,
    .navbar-buttons {
        width: 100%;
        gap: 20px;
        flex-direction: column;

        & p {
            width: 100%;
            height: 50px;
            display: flex;
            text-align: start;
            align-items: center;
            font-size: 25px;
            margin: 0;
            padding: 0 0 0 50px;
            box-sizing: border-box;
            border-bottom: 2px solid rgb(from var(--white) r g b / 50%);
            transition: 0.3s;

            &:active {
                border-color: var(--white);
            }
        }
    }
}

@media (min-width: 1400px) and (max-width: 1599px) {
    #navbar {
        padding: 10px 40px;
        gap: 15px;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    #navbar {
        padding: 10px 35px;
        gap: 10px;
    }

    .logo a {
        font-size: 48px;
    }

    #navbar .small-text {
        font-size: 20px;
    }

    .change-theme-button {
        width: 35px;
        height: 35px;
    }
}

@media (max-width: 480px) {
    #navbar {
        padding: 0;
        gap: 20px;
    }
}

@media (max-width: 300px) {
    .navbar-mobile-button {
        width: 25px;
        height: 25px;
    }

    .navbar-mobile-button svg {
        width: 80%;
    }
}
