.user-offer {
    &-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 50px;
    }

    &-header {
        width: 50%;
        margin: 0;
        display: flex;
        flex-direction: column;

        & p {
            margin: 0;
            text-align: center;
        }
    }
}

@media (max-width: 991px) {
    .user-offer-header {
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
