.loader-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-head {
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes loading-anim {
    0% {
        transform: rotate(0turn);
        border-top-color: var(--secondary-color);
    }

    50% {
        border-top-color: var(--primary-color);
    }

    100% {
        transform: rotate(1turn);
        border-top-color: var(--secondary-color);
    }
}

.loader-hexagon {
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        content: "";
        width: 75px;
        height: 75px;
        border: 15px solid var(--white);
        border-radius: 100%;
        animation: loading-anim 2s infinite;
    }
}
