.our-services {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    box-sizing: border-box;

    & p {
        margin: 0;
        text-align: center;
    }
}

.our-services-content {
    width: 80%;
    display: flex;
    justify-content: space-between;
}

.services-title-wrapper {
    position: sticky;
    top: 0;
    width: 500px;
    height: 40%;
    max-height: 40%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    padding-top: 125px;

    & p {
        text-align: start;
        margin: 0;
    }
}

.services-items-wrapper {
    display: flex;
    flex-direction: column;
    width: 40%;
    box-sizing: border-box;
    padding-top: 75px;
}

@media (max-width: 1199px) {
    .services-title-wrapper {
        align-items: center;
        margin: 20px 0;

        & p {
            text-align: center;
        }
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .our-services-content {
        flex-direction: column;
    }

    .services-items-wrapper {
        width: 100%;
    }

    .services-title-wrapper {
        position: relative;
        width: 95%;
        height: auto;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .our-services {
        width: 90%;
    }

    .our-services-content {
        width: 100%;
        align-items: center;
        flex-direction: column;
    }

    .services-items-wrapper {
        width: 100%;
        padding-top: 100px;
    }

    .services-title-wrapper {
        position: relative;
        width: 100%;
        height: auto;
        padding-top: 50px;
    }
}

@media (min-width: 651px) and (max-width: 767px) {
    .our-services {
        width: 90%;
    }

    .our-services-content {
        width: 100%;
        align-items: center;
        flex-direction: column;
    }

    .services-items-wrapper {
        width: 100%;
        padding-top: 100px;
    }

    .services-title-wrapper {
        position: relative;
        width: 100%;
        height: auto;
        padding-top: 50px;
    }
}

@media (min-width: 481px) and (max-width: 650px) {
    .our-services {
        width: 90%;
    }

    .our-services-content {
        width: 100%;
        align-items: center;
        flex-direction: column;
    }

    .services-items-wrapper {
        width: 100%;
        padding-top: 75px;
    }

    .services-title-wrapper {
        position: relative;
        width: 100%;
        height: auto;
        padding-top: 50px;
    }
}

@media (max-width: 480px) {
    .our-services {
        width: 90%;
    }

    .our-services-content {
        width: 100%;
        align-items: center;
        flex-direction: column;
    }

    .services-items-wrapper {
        width: 100%;
        padding-top: 75px;
    }

    .services-title-wrapper {
        position: relative;
        width: 100%;
        height: auto;
        padding-top: 50px;
    }
}
